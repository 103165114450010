import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getEmployees() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllEmployees',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getSpecificEmployeeData(id) {
  try {
    const response = await axios.get(
      `${APIEndpoint}/getSpecificEmployee/`+id,
      { headers: { Authorization: `Bearer ${token}`,'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',      
        withCredentials: true,
        crossorigin: true,
        mode: 'no-cors' }
     }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function storNewEmployee(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/addNewEmployee`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
   
    return error;
  }
}

export async function storeEmployeeDocument(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/employeeDocumentUpload`, data,
      { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} });

    return response;
  } catch (error) {
   
    return error;
  }
}


export async function getEmployeeDocumentTypes() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getEmployeeDocumentType',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function uploadEmployeeDocument(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/employeeMultipleDocumentUpload`, data,
      { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} });

    return response;
  } catch (error) {
   
    return error;
  }
}

export async function editEmployeeData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/editEmployee`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
   
    return error;
  }
}

export async function editEmployeeBankData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/editBankDetails`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
   
    return error;
  }
}

export async function getActiveEmployees(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/getActiveEmployees`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function getCompanyEmpsDocumentsToVerify() {
  try {
    const response = await axios.get(
      `${APIEndpoint}/getEmployeeDocumentsToVerify`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function verifyEmployeeDocuments(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/verifyEmployeeDocumentsByDocument`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}


export async function deleteEmployeeDocument(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/deleteEmployeeDocument`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}



  export async function getCompanyMasterLeaveTypes(data) {
    try {
      const response = await axios.post(
          `${APIEndpoint}/getCompanyMasterLeaveTypes`,data,
          { headers: { 'Authorization': `Bearer ${token}`} });
  
      return response;
    } catch (error) {
  
      return [];
    }
  }

    export async function assignLeaveTypes(data) {
      try {
        const response = await axios.post(
            `${APIEndpoint}/assignLeaveTypes`,data,
            { headers: { 'Authorization': `Bearer ${token}`} });
    
        return response;
      } catch (error) {
    
        return [];
      }
}

export async function getSpecificEmpLeaveTypes(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/getSpecificEmpLeaveTypes`,data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return [];
  }
}