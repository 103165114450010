import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { DatePicker, Select } from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import {
  getSpecificEmployeeData,
  editEmployeeData,
  editEmployeeBankData,
  getCompanyMasterLeaveTypes,
  assignLeaveTypes,
  getSpecificEmpLeaveTypes,
  uploadEmployeeDocument,
  getEmployeeDocumentTypes,
  deleteEmployeeDocument
} from "../../services/EmployeeAPIServices";
import {
  getAllDesignations,
  getTeams,
  getEmpStatus,
} from "../../services/MasterFileAPIServices";
import FileUpload from "../../components/FileUpload";
import EmployeeDocumentCard from "../../components/EmployeeDocumentCard";

function EditEmployee() {
  const { id } = useParams();
  const inputRef = useRef();
  const { Option } = Select;
  const spinner = document.getElementById("spinner");
  const [designationList, setDesignationList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [empTypeList, setEmpTypeList] = useState([]);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [designation, setDesignation] = useState("");
  const [team, setTeam] = useState("");
  const [nic, setNic] = useState("");
  const [emp_type, setEmpType] = useState("");
  const [joined_date, setJoinedDate] = useState("");
  const [personal_email, setPersonalEmail] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [home_tp_no, setHTNo] = useState("");
  const [permanent_address, setPermanentAddress] = useState("");
  const [location, setLocation] = useState("");
  const [kin_name, setKinName] = useState("");
  const [kin_contact_no, setKinContactNo] = useState("");
  const [email, setOfsEmail] = useState("");
  const [img_path, setImagePath] = useState("");
  const [leader_or_coordinator, setLeadership] = useState("");
  const [temporary_address, setTemporaryAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountName, setAccountName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [notes, setNotes] = useState("");
  const [leave_type_list, setLeaveTypesList] = useState([]);
  let [leave_types, setLeaveTypes] = useState([]);
  const [gender, setGender] = useState("");
  const [uploadedEmpDocuments, setUploadedEmpDocuments] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [documentDataList, setDocumentDataList] = useState([]);
  const employee_id = id;



  useEffect(() => {

    loadData();

    getCompanyMasterLeaveTypes().then((response) => {
      setLeaveTypesList(response.data.data);
    });

    getEmployeeDocumentTypes().then((res) => {
      setDocumentTypeList(res);
    })

    getAllDesignations().then((response) => {
      setDesignationList(response);
    });

    getTeams().then((response) => {
      setTeamList(response);
    });

    getEmpStatus().then((response) => {
      setEmpTypeList(response);
    });

    var emp_id = id;
    const passEmpId = { emp_id };
    getSpecificEmpLeaveTypes(passEmpId).then((response) => {
      setLeaveTypes(response.data.data.leaves);
    });
  }, []);

  function loadData() {
    getSpecificEmployeeData(id).then((res) => {
      setFirstName(res.mainData.first_name);
      setLastName(res.mainData.last_name);
      setDob(res.mainData.birthday);
      setDesignation(res.mainData.designation);
      setTeam(res.mainData.team);
      setNic(res.mainData.nic);
      setEmpType(res.mainData.emp_type);
      setJoinedDate(res.mainData.join_date);
      setPersonalEmail(res.mainData.personal_email);
      setMobileNo(res.mainData.mobile_no);
      setHTNo(res.mainData.home_tp_no);
      setPermanentAddress(res.mainData.permanent_address);
      setOfsEmail(res.mainData.email);
      setKinName(res.mainData.kin_name);
      setKinContactNo(res.mainData.kin_contact_no);
      setImagePath(res.mainData.profile_img_path);
      setLeadership(res.mainData.leader_or_coordinator);
      setTemporaryAddress(res.mainData.temporary_address);
      setAccountName(res.mainData.account_name);
      setBankName(res.mainData.bank_name);
      setBankBranch(res.mainData.branch);
      setAccountNo(res.mainData.account_no);

      setUploadedEmpDocuments(res.documents);
      setNotes(res.mainData.notes);
      setGender(res.mainData.gender);
      spinner.style.display = "none";
    });
  }



  const handleSubmitMainData = (e) => {
    e.preventDefault();
    const data = {
      first_name,
      last_name,
      dob,
      mobile_no,
      nic,
      home_tp_no,
      personal_email,
      joined_date,
      emp_type,
      designation,
      team,
      employee_id,
      leader_or_coordinator,
      permanent_address,
      temporary_address,
      kin_name,
      kin_contact_no,
      gender
    };

    editEmployeeData(data).then((response) => {
      if (response.data.status_code == 1) {
        Swal.fire("success", "Successfully updated", "success");
      } else {
        Swal.fire("warning", " " + response.data.message, "warning");
      }
    });
  };
  const handleSubmitBankData = (e) => {
    e.preventDefault();
    const bankData = {
      bankName,
      bankBranch,
      accountName,
      accountNo,
      employee_id,
    };
    editEmployeeBankData(bankData).then((response) => {
      if (response.data.status_code == 1) {
        Swal.fire("success", "Successfully updated", "success");
      } else {
        Swal.fire("warning", " " + response.data.message, "warning");
      }
    });
  };
  const changeTeam = (e) => {
    const value = e.target.value;
    setTeam(value);
  };

  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  //   $('.digit_only').keypress(function (e) {    

  //     var charCode = (e.which) ? e.which : event.keyCode    

  //     if (String.fromCharCode(charCode).match(/[^0-9]/g))    

  //         return false;                        
  // });
  const NumericOnly = (e) => { //angka only
    const reg = /^[0-9\b]+$/
    let preval = e.target.value
    if (e.target.value === '' || reg.test(e.target.value)) return true
    else e.target.value = preval.substring(0, (preval.length - 1))
  }

  function onFocus() {
    console.log("focus");
  }

  const onChangeLeaves = (value) => {
    setLeaveTypes(value);
  };

  const submitLeaveTypes = () => {
    let remove_empty_value = "";
    leave_types = leave_types.filter(function (item) {
      return item !== remove_empty_value
    })
    let emp_id = id;
    const LeaveData = { emp_id, leave_types };

    assignLeaveTypes(LeaveData).then((response) => {

      if (response.data.status_code == "1") {
        Swal.fire("success", "" + response.data.message, "success");
      } else {
        Swal.fire("warning", " " + response.data.message, "warning");
      }
    });

  }

  const submitDocuments = (documentList) => {
    if (documentType == 0 || documentType == "") {
      Swal.fire("warning", "Please select document type", "warning");
    } else if (documentList.length == 0) {
      Swal.fire("warning", "Please upload document", "warning");
    } else {
      const formData = new FormData();
      documentList.forEach(file => {
        formData.append('files[]', file);
      });
      formData.append('document_type', documentType);
      formData.append('emp_id', id);

      uploadEmployeeDocument(formData).then((response) => {

        if (response.data.status_code == "1") {
          Swal.fire("success", "" + response.data.message, "success");

          changeDropZoneList();
          loadData()
        } else {
          Swal.fire("warning", " " + response.data.message, "warning");
        }
      });
    }

  }
  const changeDropZoneList = () => {
    setDocumentDataList([]);
  }
  const deleteDocument = (documentID) => {
    const delete_record = {documentId:documentID};
    deleteEmployeeDocument(delete_record).then((response) => {
       
      if (response.data.status_code == "1") {
        Swal.fire("success", "" + response.data.message, "success");
        loadData()
      } else {
        Swal.fire("warning", " " + response.data.message, "warning");
      }
    });
}


  return (
    <>
      <div classNameName="row">
        <div classNameName="col-12">
          <h3>
            Edit Employee - {first_name} {last_name}
          </h3>
        </div>
      </div>
      <section className="users-edit">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        href="#account"
                        role="tab"
                        id="account-tab"
                        className="nav-link d-flex align-items-center active"
                        data-toggle="tab"
                        aria-controls="account"
                        aria-selected="true"
                      >
                        <i className="ft-user mr-1"></i>
                        <span className="d-none d-sm-block">Information</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#information"
                        role="tab"
                        id="information-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="information"
                        aria-selected="false"
                      >
                        <i className="ft-home mr-1"></i>
                        <span className="d-none d-sm-block">Bank Details</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#document"
                        role="tab"
                        id="document-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="document"
                        aria-selected="false"
                      >
                        <i className="ft-folder mr-1"></i>
                        <span className="d-none d-sm-block">Documents</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#leave-types"
                        role="tab"
                        id="leave-types-tab"
                        className="nav-link d-flex align-items-center"
                        data-toggle="tab"
                        aria-controls="leave-types"
                        aria-selected="false"
                      >
                        <i className="ft-folder mr-1"></i>
                        <span className="d-none d-sm-block">Leave Types</span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade mt-2 show active"
                      id="account"
                      role="tabpanel"
                      aria-labelledby="account-tab"
                    >
                      <form
                        onSubmit={(e) => {
                          handleSubmitMainData(e);
                        }}
                      >
                        <div className="form-row">
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="first_name">
                                First Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="first_name"
                                className="form-control"
                                placeholder="First Name"
                                name="first_name"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="last_name">
                                Last Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="last_name"
                                className="form-control"
                                placeholder="Last Name"
                                name="last_name"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="dob">Date of Birth</label>
                              <DatePicker
                                className="form-control"
                                placeholder="Date of Birth"
                                format="YYYY-MM-DD"
                                selected={moment(dob)}
                                value={moment(dob)}
                                onChange={(dob) => setDob(dob)}
                                // maxDate={subYears(new Date(), 18)}
                                disabledDate={(current) => {
                                  return (
                                    moment().subtract(18, "years") <= current
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="mobile_no">
                                Mobile No{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="mobile_no"
                                className="form-control"
                                placeholder="Mobile No (Ex:+947XXXXXXXX)"
                                pattern="([+][0-9]{11})$"
                                name="mobile_no"
                                value={mobile_no}
                                onChange={(e) => setMobileNo(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="nic">
                                NIC <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="nic"
                                className="form-control"
                                placeholder="NIC"
                                name="nic"
                                pattern="^([0-9]{9}[x|X|v|V]|[0-9]{12})$"
                                value={nic}
                                onChange={(e) => setNic(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="home_tp_no">
                                Home TP Number{" "}

                              </label>
                              <input
                                type="text"
                                id="home_tp_no"
                                className="form-control"
                                placeholder="Home TP Number"
                                name="home_tp_no"
                                value={home_tp_no}
                                onChange={(e) => setHTNo(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="personal_email">
                                Personal Email{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="personal_email"
                                className="form-control"
                                placeholder="Personal Email"
                                name="personal_email"
                                value={personal_email}
                                onChange={(e) =>
                                  setPersonalEmail(e.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-4">
                                Joined Date{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <DatePicker
                                disabledDate={(current) =>
                                  current.isAfter(moment())
                                }
                                dateFormat="yyyy-MM-dd"
                                selected={moment(joined_date)}
                                value={moment(joined_date)}
                                onChange={(date) => setJoinedDate(date)}
                                className="form-control"
                                placeholder="Joined Date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                Permanent Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="form-action-4"
                                className="form-control pickadate"
                                name="date-closed"
                                placeholder="Permanent Address"
                                value={permanent_address}
                                onChange={(e) =>
                                  setPermanentAddress(e.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                Temporary Address
                              </label>
                              <input
                                type="text"
                                id="form-action-4"
                                className="form-control pickadate"
                                name="date-closed"
                                placeholder="Temporary Address"
                                value={temporary_address}
                                onChange={(e) =>
                                  setTemporaryAddress(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                kin name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="form-action-4"
                                className="form-control"
                                name="date-closed"
                                placeholder="Kin name"
                                value={kin_name}
                                onChange={(e) => setKinName(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                kin contact no
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="form-action-4"
                                className="form-control"
                                name="date-closed"
                                placeholder="Kin contact no"
                                value={kin_contact_no}
                                onChange={(e) =>
                                  setKinContactNo(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-2 col-12">
                            <label className="form-label">
                              Gender <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              value={gender}
                              required
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="" selected disabled> select option</option>
                              <option value="1"> Male</option>
                              <option value="0"> Female</option>
                            </select>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                Leader / Coordinator
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="leadership"
                                name="leadership"
                                className="form-control"
                                value={leader_or_coordinator}
                                onChange={(e) => setLeadership(e.target.value)}
                                required
                              >
                                <option value="No">No</option>
                                <option value="Leader">Leader</option>
                                <option value="Coordinator">Coordinator</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                location
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="location"
                                name="location"
                                className="form-control"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                required
                              >
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="India">India</option>
                                <option value="UK">UK</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-4 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                Employment Status{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="emp_type"
                                name="emp_type"
                                className="form-control"
                                value={emp_type}
                                onChange={(e) => setEmpType(e.target.value)}
                                required
                              >
                                <option value="" disabled>
                                  Select employee status
                                </option>
                                {empTypeList.map((item, i) => {
                                  return (
                                    <option value={item.id}>
                                      {item.employment_type}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-3">
                                Designation{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="designation"
                                name="designation"
                                className="form-control"
                                value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                                required
                              >
                                <option value="" disabled>
                                  Select designation
                                </option>
                                {designationList.map((item, i) => {
                                  return (
                                    <option value={item.id}>
                                      {item.designation_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <div className="form-group position-relative">
                              <label for="form-action-4">
                                Team <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="team"
                                name="team"
                                className="form-control"
                                value={team}
                                onChange={changeTeam}
                                required
                              >
                                <option value="" disabled>
                                  Select team
                                </option>
                                {teamList.map((item, i) => {
                                  return (
                                    <option value={item.id}>
                                      {item.team_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                          <button
                            type="submit"
                            className="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>

                    <div
                      className="tab-pane fade mt-2"
                      id="information"
                      role="tabpanel"
                      aria-labelledby="information-tab"
                    >
                      <form
                        onSubmit={(e) => {
                          handleSubmitBankData(e);
                        }}
                      >
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <div className="form-group">
                              <label for="users-edit-twitter">
                                Bank Name:{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="users-edit-twitter"
                                className="form-control"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label for="users-edit-facebook">
                                Branch <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="users-edit-facebook"
                                className="form-control"
                                value={bankBranch}
                                onChange={(e) => setBankBranch(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label for="users-edit-google">
                                Account No{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="users-edit-google"
                                className="form-control digit_only"
                                value={accountNo}
                                onChange={(e) => setAccountNo(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label for="users-edit-linkedin">
                                Account Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="users-edit-linkedin"
                                className="form-control"
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-12 d-flex justify-content-end flex-sm-row flex-column mt-3 mt-sm-0">
                            <button
                              type="submit"
                              className="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div
                      className="tab-pane fade mt-2"
                      id="document"
                      role="tabpanel"
                      aria-labelledby="document-tab"
                    >
                      <fieldset>
                        <form id="form-document">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  Document Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="form-control"
                                  name="documentType"
                                  id="documentType"
                                  value={documentType}
                                  required
                                  onChange={(e) =>
                                    setDocumentType(e.target.value)
                                  }
                                >
                                  <option value="" disabled>
                                    {" "}
                                    Select document type
                                  </option>
                                  {documentTypeList.map((item, i) => {
                                    return (
                                      <option value={item.id}>  {item.name}</option>
                                    );
                                  })}


                                </select>
                              </div>
                            </div>

                            <div className="col-12 col-md-8">
                              {/* <Dropzone onDrop={this.onDrop}></Dropzone> */}
                              <FileUpload submitDocuments={submitDocuments} changeDropZoneList={documentDataList} />
                            </div>
                          </div>
                        </form>
                      </fieldset>
                      <fieldset>
                        <br />
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <h5>Uploaded Documents</h5>
                          </div>

                          {uploadedEmpDocuments.map((document, i) => {
                            let documentsList = [];
                            return (
                              <div className="col-12 col-md-4 mb-2">
                                <label for="basic-form-8">
                                  <b> {document.name} </b>
                                  <small> - {document.approval_status.replace("_", " ")}</small>
                                </label>
                                {document.employee_document_list.length !== 0 ? (

                                  <>

                                    <EmployeeDocumentCard docArray={document.employee_document_list} page={'edit-employee'} deleteDocument={deleteDocument}></EmployeeDocumentCard>
                                    {/* <DocViewer documents={documentsList} pluginRenderers={DocViewerRenderers} config={{
                                      header: {
                                        disableHeader: false,
                                        disableFileName: true,
                                        retainURLParams: false
                                      }}}/> */}

                                  </>

                                ) : (
                                  <p>No PDF file specified.</p>
                                )}
                              </div>
                            );
                          })}

                        </div>
                      </fieldset>
                    </div>
                    <div
                      className="tab-pane fade mt-2"
                      id="leave-types"
                      role="tabpanel"
                      aria-labelledby="leave-types-tab"
                    >

                      <div className="form-row">
                        <div className="col-md-10 col-12">
                          <label className="form-label">
                            Assign Leave Type <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            mode="multiple"
                            value={leave_types}
                            onFocus={onFocus}
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Please select"
                            onChange={onChangeLeaves}
                            filterOption={(input, option) =>
                              option.children
                                .toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            required
                          >
                            {leave_type_list.map((item, i) => {
                              return (
                                <Option value={item.id}>
                                  {item.leave_type}
                                </Option>
                              );
                            })}
                          </Select>

                        </div>
                        <div className="col-md-1 col-12">
                          <label className="form-label">

                          </label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={submitLeaveTypes}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default EditEmployee;
