import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';
import $ from "jquery";



export async function getUserRoles() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getUserRoles',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
  
    return [];
  }
}

export async function getAllPermissions() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllPermissions',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    
    return [];
  }
}

export async function rolePermissionStore(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/storeRolePermission',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function getRolePermissions(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getGrantedMenus',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getGrantedPermissions(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getGrantedPermissions',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getRightsAccess(base_data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getGrantedPermissions',base_data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    let access_checking = false;
    let checking_count = 0;
    
  

    $.each(response.data.data, function (k, v) {
      const href_path = v.href;
  
  
      if (href_path == base_data.current_path) {
        checking_count++;
      } else if (
        href_path == "existing-employees" &&
        base_data.current_path == "employee-edit"
      ) {
        checking_count++;
      } else if (
        href_path == "existing-employees" &&
        base_data.current_path == "employee-view"
      ) {
        checking_count++;
      } else if (base_data.current_path == "account") {
        checking_count++;
      }
    });

    if (checking_count == 0) {
      access_checking = false;

    } else {
      access_checking = true;
    }

    return access_checking;
  } catch (error) {
    console.log(error);
    return error;
  }
}