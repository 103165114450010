import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import "antd/dist/antd.css";
import { getCompanyEmpsDocumentsToVerify, verifyEmployeeDocuments } from "../services/EmployeeAPIServices";
import { adminUrl } from "../UrlPaths";
import DocVerifyCard from "../components/DocVerifyCard";
import EmployeeDocumentCard from "../components/EmployeeDocumentCard";

function DocumentVerify() {
    const spinner = document.getElementById("spinner");
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, []);


    function loadData() {
        setLoading(true);
        getCompanyEmpsDocumentsToVerify().then((response) => {
            spinner.style.display = "none";

            setTableData(response);
            // setLoading(false);
        });

    }

    const decisionStore = (id, is_verified) => {
        const data = { id, is_verified }
        verifyEmployeeDocuments(data).then((res) => {
            if (res.data.status_code == 1) {
                Swal.fire("Success", "" + res.data.message, "success");
                loadData();
            } else {
                Swal.fire("Warning", " " + res.data.message, "warning");
            }
        });
    }


    function searchFunction() {
        let searchText = $('#search').val().toUpperCase();
        var mainCollapseSet, collapseRow, rowSet, i, txtValue;

        mainCollapseSet = document.getElementById("collapse_section");
        collapseRow = mainCollapseSet.getElementsByClassName("headingCollapseSet");
        for (i = 0; i < collapseRow.length; i++) {
            rowSet = collapseRow[i];
            if (rowSet) {
                txtValue = rowSet.textContent;
                console.log('txtValue', txtValue);
                if (txtValue.toUpperCase().indexOf(searchText) > -1) {
                    // $('#headingAccordion' + i).css('display', 'block')
                    $('#collapseFullRow' + i).css('display', 'block')
                } else {
                    // $('#headingAccordion' + i).css('display', 'none')
                    $('#collapseFullRow' + i).css('display', 'none')
                }
            }
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h3> Employee Document Verification</h3>
                </div>
            </div>
            <section>
                <div className="row match-height">
                    <div className="col-12">
                        <div class="card collapse-icon accordion-icon-rotate">
                            <div class="accordion" id="accordionExample2">
                                {tableData.length === 0 ? (<>
                                    <div class="card-header p-2">
                                        <h6 class="card-title">No employee-side documents uploaded...</h6>
                                    </div>
                                </>) : (<>
                                    <div class="card-header">
                                        <fieldset class="form-group position-relative has-icon-right">
                                            <input type="text" class="form-control" id="search" name="search" onChange={() => searchFunction()} placeholder="search by employee name" />
                                            <div class="form-control-position">
                                                <i class="fa fa-search"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="card-body" id="collapse_section">
                                        {tableData.map((employee, index) => (<>
                                            <div id={`collapseFullRow${index}`}>
                                                <div className="headingCollapseSet card-header border-bottom pb-3" id={`headingAccordion${index}`}>
                                                    <a data-toggle="collapse" href={`#accordion${index}`} aria-expanded="false" aria-controls={`accordion${index}`} className="card-title">
                                                        <img class="avatar" style={{ margin: '0 10px 0 0' }} src={`${adminUrl}assets/profile_imgs/${employee.employeeData.profile_img_path}`} alt="avatar" height="35" width="35" />{employee.employeeData.name_with_initial} - <small className="text-muted">{employee.employeeData.designation_name}</small>
                                                    </a>
                                                </div>
                                                <div id={`accordion${index}`} class="collapse" aria-labelledby={`headingAccordion${index}`} data-parent="#accordionExample2">
                                                    <div class="card-body">
                                                        <div className="row d-flex">

                                                            {employee.documents.map((documentList, i) => {
                                                                return (<>
                                                                    <div className="col-md-4">
                                                                        <div class="card card-outline-secondary box-shadow-0 text-center">
                                                                            <div class="card-content">
                                                                                <div class="card-body p-2"></div>
                                                                                <label for="basic-form-8">
                                                                                    <b> {documentList.name} </b><br></br>
                                                                                    <small>  {documentList.approval_status.replace("_", " ")}</small>
                                                                                </label>
                                                                                <EmployeeDocumentCard docArray={documentList.employee_document_list} page={'verification'} decisionStore={decisionStore}></EmployeeDocumentCard>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                );
                                                            })}


                                                            {/* <DocVerifyCard rowId={employee.id} name={"Police Report"} pdfPath={employee.police_report} isApproved={employee.police_report_approval} docType={"policeReport"} decisionStore={decisionStore} />
                                                            <DocVerifyCard rowId={employee.id} name={"Service Letters"} pdfPath={employee.service_letters} isApproved={employee.service_letters_approval} docType={"service"} decisionStore={decisionStore} />
                                                            <DocVerifyCard rowId={employee.id} name={"Birth Certificate"} pdfPath={employee.birth_certificate} isApproved={employee.birth_certificate_approval} docType={"birthCertificate"} decisionStore={decisionStore} />
                                                            <DocVerifyCard rowId={employee.id} name={"National Identification"} pdfPath={employee.nic} isApproved={employee.nic_approval} docType={"nic"} decisionStore={decisionStore} />
                                                            <DocVerifyCard rowId={employee.id} name={"Educational Certificates"} pdfPath={employee.educational_certificates} isApproved={employee.educational_certificates_approval} docType={"education"} decisionStore={decisionStore} />
                                                            <DocVerifyCard rowId={employee.id} name={"Previous Job Payslips"} pdfPath={employee.previous_job_payslips} isApproved={employee.previous_job_payslips_approval} docType={"payslip"} decisionStore={decisionStore} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>))}
                                    </div>

                                </>)}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default DocumentVerify;
