import React, { useState, useEffect, useRef } from "react";
import { Input, Table, Button, Modal, Select,Tooltip } from "antd";

import "antd/dist/antd.css";
import { getEmployees } from "../services/EmployeeAPIServices";
import Swal from "sweetalert2";
import { SearchOutlined } from "@ant-design/icons";

import {
  getAllMessages,
  getMessageReplies,
  storeNewMessage,
  storeReplyMessage,
  closeThreadMsg,
} from "../services/MessageAPIServices";

import { displayUrl, attachmentUrl } from "../UrlPaths";
import moment from "moment";

function Message() {
  const { Option } = Select;
  const inputRef = useRef();
  const inputRef2 = useRef();
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [tableLoading, setTableLoading] = useState(false);

  const [messageDate, setMessageDate] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [mainMsgId, setMainMsgId] = useState(0);
  const [empImagePath, setEmpImagePath] = useState("");
  const [mainMessage, setMainMessage] = useState("");
  const [mainMsgAttach, setMainMsgAttach] = useState("");
  const [mainMsgType, setMainMsgType] = useState("");
  const [replyDataList, setReplyDataList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [newMessageSubject, setNewMessageSubject] = useState("");
  const [emp_id, setEmployeeId] = useState("0");
  const [newMsgAttach, setNewMsgAttach] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [replyMsgAttach, setReplyMsgAttach] = useState("");

  const [isReplyDisabled, setIsReplyDisabled] = useState(true);

  const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/g;
  let separatedArray = [];
  const truncate = (input) =>{
      return input?.length > 30 ? `${input.substring(0, 25)}...` : input;
  }
    

  const columns = [
    {
      key: "email",
      title: "Email",
      render: (record) => {
        return (
            <>
              <div className="flex">
                <img
                    src={`${displayUrl}/${record.profile_img_path}`}
                    className="avatar mr-2"
                    alt="avatar"
                    width="30"
                    height="30"
                />
                <span>{record.name_with_initial}</span><br/>
                <span className="text-muted">{record.email}</span>
              </div>
            </>
        );
      },
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type employee email"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "msg_type",
      title: "Type",
      width: 100,
      render: (record) => {
        return (
          <>
            {record.msg_type == "inbox" ? (
              <div>
                <span className="badge badge-success mb-1 mr-2">
                  {record.msg_type.charAt(0).toUpperCase() +
                    record.msg_type.slice(1)}
                </span>
              </div>
            ) : (
              <div>
                <span className="badge badge-primary mb-1 mr-2">
                  {record.msg_type.charAt(0).toUpperCase() +
                    record.msg_type.slice(1)}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "created_at",
      title: "Date",
      dataIndex: "message_date",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type Date"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.message_date.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "subject",
      title: "Subject",
      dataIndex: "subject",
    },
    {
      key: "message_body",
      title: "Description",
      dataIndex: "message_body",
      render: (text) => {
        return (
          <>
            <div className="flex">
            <Tooltip placement="topLeft" title={text}>
              <span>{truncate(text)} </span>
            </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      key: "reply_status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              <button
                  className="badge badge-pill bg-light-success action-btn"
                  onClick={() => viewData(record)}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </button>
              &nbsp;
              {record.reply_status == "PENDING" ? (
                <span className="badge bg-light-warning">
                  {record.reply_status}
                </span>
              ) : record.reply_status == "ON-GOING" ? (
                <span className="badge bg-light-success">
                  {record.reply_status}
                </span>
              ) : (
                <span className="badge bg-light-danger">
                  {record.reply_status} ({record.replies_count})
                </span>
              )}

            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getEmployees().then((response) => {
      setEmployeeList(response);
    });

    setTableLoading(true);
    getAllMessages().then((response) => {
      spinner.style.display = "none";
      setTableData(response);
      setTableLoading(false);
    });
  }, []);

  const showModal = () => {
    $('.custom-file-label').html("Choose file");
    newMsgAttach != "" ? inputRef.current.value = "":'';
    setIsModalOpen(true);
  };

  const handleNewMsgSend = (e) => {
    e.preventDefault();

    if (
      newMessageSubject == "" ||
      newMessageSubject == null ||
      newMessage == "" ||
      newMessage == null ||
      emp_id == "" ||
      emp_id == null ||
      emp_id == 0
    ) {
      Swal.fire("warning", "Please fill the required data", "warning");
    } else {
      const data = new FormData();

      data.append("document", newMsgAttach);
      data.append("subject", newMessageSubject);
      data.append("message_body", newMessage);
      data.append("emp_id", emp_id);
    
      setLoading(true);
      storeNewMessage(data).then((response) => {
      
        if (response.data.status_code == "1") {
          Swal.fire("success", "Successfully sent", "success");
          setNewMessage("");
          setNewMessageSubject("");
          setEmployeeId("");
          document.getElementById("form-message").reset();

          setTableLoading(true);
          getAllMessages().then((response) => {
            setTableData(response);
            setTableLoading(false);
          });
          
          setIsModalOpen(false);
        } else {
          Swal.fire("warning", " " + response.data.message, "warning");
        }
        setLoading(false);
      });
    }
  };

  const handleReplyMsgSend = (e) => {
    e.preventDefault();

    if (replyMessage == "" || replyMessage == null) {
      Swal.fire("warning", "Please fill the required data", "warning");
    } else {
      const data = new FormData();

      data.append("document", replyMsgAttach);
      data.append("replyMessage", replyMessage);
      data.append("mainMsgId", mainMsgId);
      setLoading(true);

      storeReplyMessage(data).then((response) => {
   
        if (response.data.status_code == "1") {
          Swal.fire("success", "Successfully sent", "success");
          setMainMsgId("");
          setReplyMessage("");
          document.getElementById("form-reply").reset();

          setTableLoading(true);
          getAllMessages().then((response) => {
            setTableData(response);
            setTableLoading(false);
          });

          setVisible(false);
        } else {
          Swal.fire("warning", " " + response.data.message, "warning");
        }
        setLoading(false);
      });
    }
  };

  const handleCloseThread = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure close chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("ok");
        const closeMsg = {
          mainMsgId,
        };

        closeThreadMsg(closeMsg).then((response) => {
          console.log(response);
          if (response.status_code == "1") {
            Swal.fire("success", "Successfully sent", "success");
            setMainMsgId("");
            setReplyMessage("");
            document.getElementById("form-reply").reset();

            setTableLoading(true);
            getAllMessages().then((response) => {
              setTableData(response);
              setTableLoading(false);
            });

            setVisible(false);
          } else {
            Swal.fire("warning", " " + response.message, "warning");
          }
        });
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const viewData = (data) => {
    var mainMessageID = data.id;

    $('.custom-file-label').html("Choose file");

    replyMsgAttach != "" ? inputRef2.current.value = "":'';

    const MsgData = {
      mainMessageID,
    };
    getMessageReplies(MsgData).then((response) => {
      setReplyDataList(response);
      setMainMsgId(data.id);
      setModalTitle(
        data.msg_type.charAt(0).toUpperCase() +
          data.msg_type.slice(1) +
          ": " +
          data.name_with_initial +
          " - " +
          data.subject
      );
      setMainMsgAttach(data.attachment);
      setMessageDate(data.message_date);
      setMessageStatus(data.reply_status);
      setMainMessage(data.message_body);
      setEmpImagePath(displayUrl + "/" + data.profile_img_path);
      setMainMsgType(data.msg_type);

      if (data.reply_status == "CLOSED") {
        setIsReplyDisabled(true);
      } else {
        setIsReplyDisabled(false);
      }

      setVisible(true);
    });
  };

  useEffect(() => {}, [replyDataList]);

  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const onChange = (value) => {
    setEmployeeId(value);
    
  };

  const handleImage = async (event) => {
    setReplyMsgAttach(event);
    $('.custom-file-label').html(event.name)

  };

  const handleNewMsgImage = async (event) => {
    setNewMsgAttach(event);
    $('.custom-file-label').html(event.name)

  };


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Messages</h3>
        </div>
      </div>
      <section>
        <div className="row">
          <div className="col-12">
            <div className="col-2">
              <button type="button" class="btn btn-danger" onClick={showModal}>
                <i class="ft-mail mr-2"></i>New
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={tableLoading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title="New Message"
        closable={false}
        open={isModalOpen}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleNewMsgSend}
          >
            <i class="ft-send m-0 mr-sm-1"></i> Send
          </Button>,
        ]}
      >
        <form id="form-message">
          <div className="card-content">
            <div className="card-body">
              <div className="form-group">
                <label className="" for="compose-email-to">
                  To <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  showSearch
                  className="form-control"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  required
                >
                  <Option value="" selected disable>
                    Select Employee To Send *
                  </Option>
                  {employeeList.map((item, i) => {
                    return (
                      <Option value={item.id} key={i}>
                        {item.first_name} {item.last_name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="form-group">
                <label className="sr-only" for="compose-email-subject">
                  Subject <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="compose-email-subject"
                  className="form-control"
                  placeholder="Subject *"
                  value={newMessageSubject}
                  onChange={(e) => setNewMessageSubject(e.target.value)}
                  required
                />
              </div>

              <div className="snow-container border rounded">
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder="Description...*"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="form-group mt-2">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    accept="image/png,image/jpeg,image/jpg,application/pdf"
                    id="compose-email-attach1"
                    ref={inputRef}
                    onChange={(e) => handleNewMsgImage(inputRef.current.files[0])}
                    // onChange={(e) => setNewMsgAttach(inputRef.current.files[0])}
                  />
                  <label
                    className="custom-file-label"
                    for="compose-email-attach"
                  >
                    Choose file
                  </label>
                  <p class="text-muted mb-0 mt-1 mt-sm-0">
                    <small>*Allowed JPG, PNG , JPEG or PDF.</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        title={modalTitle}
        closable={false}
        visible={visible}
        onOk={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="danger"
            loading={loading}
            onClick={handleCloseThread}
            disabled={isReplyDisabled}
          >
            <i class="ft-x m-0 mr-sm-1"></i> Close Thread
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleReplyMsgSend}
            disabled={isReplyDisabled}
          >
            <i class="ft-send m-0 mr-sm-1"></i> Reply
          </Button>,
        ]}
      >
        <form id="form-reply">
          <h6>{mainMessage}</h6>
          {mainMsgAttach != null ? (
            <>
              <p>
                Attachment:{" "}
                <a
                  class="btn btn-info bg-light-info"
                  style={{padding:'5px', marginRight:'5px',borderRadius:'5px'}}
                  onClick={() =>
                    popupDocument(`${attachmentUrl}/${mainMsgAttach}`)
                  }
                >
                  <i className="ft-eye"></i> View
                </a>
              </p>
            </>
          ) : (
            <></>
          )}

          <span style={{ color: "black" }}>{messageDate}</span>

          {replyDataList.length == 0 ? (
            <>
              {" "}
              <i>
                <p class="grey"> No reply yet</p>
              </i>
            </>
          ) : (
            <>
              <i>
                <p class="grey">
                  {" "}
                  <i className="ft-message-circle"></i> Replies:
                  {replyDataList.length}
                </p>
              </i>
            </>
          )}

          {replyDataList.map((item, i) => {
            if(item.attachment != null){
              let cleared_string = item.attachment.replace(regex, " ");
              separatedArray = cleared_string.split(',');
            }else{
              separatedArray = [];
            }
           
            return (
              <>
                <div
                  id="headingCollapse1"
                  class="card-header p-0"
                  style={{ background: "#fff" }}
                >
                  <a class="collapsed email-app-sender list-group-item list-group-item-action border-0 opened-email">
                    <div class="media">
                      <span class="avatar mr-2">
                        <img
                          src={`${displayUrl}/${item.profile_img_path}`}
                          width="32"
                          height="32"
                          alt="avatar"
                        />
                      </span>
                      <div class="media-body">
                        <h6 class="list-group-item-heading">
                          {item.name_with_initial}
                        </h6>
                        {item.attachment != null ? (
                          <>
                            <p className="mb-1">
                              Attachment:{" "}
                              {separatedArray.map((attachmentData, ind) => {
                                 return (
                                  <a
                                    class="btn-info bg-light-info"
                                    style={{padding:'5px', marginRight:'5px',borderRadius:'5px'}}
                                    onClick={() =>
                                      popupDocument(
                                        `${attachmentUrl}/${attachmentData.trim()}`
                                      )
                                    }
                                  >
                                    <i className="ft-eye"></i> View
                                  </a>
                                )})}
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                        <p class="list-group-item-text m-0">
                          {item.reply_message}
                          <span class="float-right text muted grey">
                            {moment(item.created_at).format("DD/MM/YYYY hh:mm")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </>
            );
          })}

          <div className="snow-container border rounded">
            <textarea
              className="form-control"
              rows={3}
              placeholder="Reply something..."
              value={replyMessage}
              onChange={(e) => setReplyMessage(e.target.value)}
            ></textarea>
          </div>

          <div className="form-group mt-2">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="compose-email-attach"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                ref={inputRef2}
                // onChange={handleImage}
                onChange={(e) => handleImage(inputRef2.current.files[0])}
                // onChange={(e) => setReplyMsgAttach(inputRef.current.files[0])}
              />
              <label className="custom-file-label" for="compose-email-attach">
                Attach file
              </label>
              <p class="text-muted mb-0 mt-1 mt-sm-0">
                <small>*Allowed JPG, PNG , JPEG or PDF.</small>
              </p>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default Message;
