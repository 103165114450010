import React, { useState, useEffect } from "react";
import { DatePicker,Select } from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import { storNewEmployee,getCompanyMasterLeaveTypes } from "../../services/EmployeeAPIServices";

import {
  getAllDesignations,
  getTeams,
  getEmpStatus,
} from "../../services/MasterFileAPIServices";

function NewEmployee() {
  const { Option } = Select;
  const spinner = document.getElementById("spinner");
  const [designationList, setDesignationList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [empTypeList, setEmpTypeList] = useState([]);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [designation, setDesignation] = useState("");
  const [team, setTeam] = useState("");
  const [nic, setNic] = useState("");
  const [emp_type, setEmpType] = useState("");
  const [joined_date, setJoinedDate] = useState("");
  const [personal_email, setPersonalEmail] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [home_tp_no, setHTNo] = useState("");
  const [permanent_address, setPermanentAddress] = useState("");
  const [location, setLocation] = useState("");
  const [notes, setNotes] = useState("");
  const [email, setOfsEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPwd, setConfirmedPwd] = useState("");
  const [employee_id, setEmpId] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [leave_type_list, setLeaveTypesList] = useState([]);
  let [leave_types, setLeaveTypes] = useState([]);

  useEffect(() => {
    getAllDesignations().then((response) => {
      setDesignationList(response);
    });

    getTeams().then((response) => {
      setTeamList(response);
    });

    getEmpStatus().then((response) => {
      setEmpTypeList(response);
      spinner.style.display = "none";
    });

    getCompanyMasterLeaveTypes().then((response) => {
      setLeaveTypesList(response.data.data);
    });
  }, []);


  const handleSubmitMainData = (e) => {
    e.preventDefault();
    let remove_empty_value  ="";
    leave_types = leave_types.filter(function(item) {
      return item !== remove_empty_value
  })
    const data = {
      first_name,
      last_name,
      dob,
      mobile_no,
      nic,
      home_tp_no,
      personal_email,
      joined_date,
      emp_type,
      designation,
      team,
      employee_id,
      password,
      email,
      notes,
      gender,
      leave_types
    };
    if (password.length < 8) {
      Swal.fire(
        "warning",
        "Password must be at least 8 characters...!",
        "warning"
      );
    } else if (password != confirmedPwd) {
      Swal.fire("warning", "Passwords do not match..!", "warning");
    } else {
      setButtonLoading(true);
      storNewEmployee(data).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("success", "" + response.data.message, "success");
          document.getElementById("form-new-employee").reset();
          setFirstName("");
          setLastName("");
          setDob("");
          setDesignation("");
          setTeam("");
          setNic("");
          setEmpType("");
          setJoinedDate("");
          setPersonalEmail("");
          setMobileNo("");
          setHTNo("");
          setOfsEmail("");
          setPassword("");
          setConfirmedPwd("");
          setNotes("");
          setLeaveTypes([]);
          setGender("");
        } else {
          Swal.fire("warning", " " + response.data.message, "warning");
        }
        setButtonLoading(false);
      });
    }
  };

  function onFocus() {
    console.log("focus");
  }

  const onChangeLeaves = (value) => {
    setLeaveTypes(value);
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> New Employee Creation</h3>
        </div>
      </div>
      <section id="action-form-layout">
        <div className="row match-height">
          <div className="col-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-content">
                <div className="card-body">
                  <form
                    id="form-new-employee"
                    onSubmit={(e) => {
                      handleSubmitMainData(e);
                    }}
                  >
                    <div className="form-row">
                      <div className="col-md-6 col-12">
                        <div className="form-group position-relative">
                          <label for="first_name">
                            First Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            className="form-control"
                            placeholder="First Name"
                            name="first_name"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group position-relative">
                          <label for="last_name">
                            Last Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            className="form-control"
                            placeholder="Last Name"
                            name="last_name"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 col-12">
                        <div className="form-group position-relative">
                          <label for="dob">Date of Birth</label>
                          <DatePicker
                            className="form-control"
                            placeholder="Date of Birth"
                            format="YYYY-MM-DD"
                            selected={dob}
                            value={dob}
                            onChange={(date) => setDob(date)}
                            // maxDate={subYears(new Date(), 18)}
                            disabledDate={(current) => {
                              return moment().subtract(18, "years") <= current;
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group position-relative">
                          <label for="mobile_no">
                            Mobile No <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="mobile_no"
                            className="form-control"
                            placeholder="Mobile No (Ex:+947XXXXXXXX)"
                            pattern="([+][0-9]{11})$"
                            name="mobile_no"
                            value={mobile_no}
                            onChange={(e) => setMobileNo(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 col-12">
                        <div className="form-group position-relative">
                          <label for="nic">
                            NIC/Passport <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="nic"
                            className="form-control"
                            placeholder="NIC"
                            name="nic"
                            pattern="^([0-9]{9}[x|X|v|V]|[0-9]{12})$"
                            value={nic}
                            onChange={(e) => setNic(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group position-relative">
                          <label for="home_tp_no">Home TP Number </label>
                          <input
                            type="text"
                            id="home_tp_no"
                            className="form-control"
                            placeholder="Home TP Number"
                            name="home_tp_no"
                            value={home_tp_no}
                            onChange={(e) => setHTNo(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 col-12">
                        <div className="form-group position-relative">
                          <label for="personal_email">
                            Personal Email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="personal_email"
                            className="form-control"
                            placeholder="Personal Email"
                            name="personal_email"
                            value={personal_email}
                            onChange={(e) => setPersonalEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <label className="form-label">
                          Gender <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          value={gender}
                          required
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="" selected disabled> select option</option>
                          <option value="1"> Male</option>
                          <option value="0"> Female</option>
                        </select>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group position-relative">
                          <label for="form-action-4">
                            Joined Date <span style={{ color: "red" }}>*</span>
                          </label>
                          <DatePicker
                            disabledDate={(current) =>
                              current.isAfter(moment())
                            }
                            dateFormat="yyyy-MM-dd"
                            selected={joined_date}
                            value={joined_date}
                            onChange={(date) => setJoinedDate(date)}
                            className="form-control"
                            placeholder="Joined Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-4 col-12">
                        <div className="form-group position-relative">
                          <label for="form-action-3">
                            Employment Status{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="emp_type"
                            name="emp_type"
                            className="form-control"
                            value={emp_type}
                            onChange={(e) => setEmpType(e.target.value)}
                            required
                          >
                            <option value="" disabled>
                              select employee status
                            </option>
                            {empTypeList.map((item, i) => {
                              return (
                                <>
                                  {item.status == 1 ? (
                                    <option value={item.id} key={i}>
                                      {item.employment_type}
                                    </option>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group position-relative">
                          <label for="form-action-3">
                            Designation <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="designation"
                            name="designation"
                            className="form-control"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                            required
                          >
                            <option value="" disabled>
                              select designation
                            </option>
                            {designationList.map((item, i) => {
                              return (
                                <>
                                  {item.status == 1 ? (
                                    <option value={item.id} key={i}>
                                      {item.designation_name}
                                    </option>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group position-relative">
                          <label for="form-action-4">
                            Team <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="team"
                            name="team"
                            className="form-control"
                            value={team}
                            onChange={(e) => setTeam(e.target.value)}
                            required
                          >
                            <option value="" disabled>
                              select team
                            </option>
                            {teamList.map((item, i) => {
                              return (
                                <>
                                  {item.status == 1 ? (
                                    <option value={item.id} key={i}>
                                      {item.team_name}
                                    </option>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                    <div className="col-md-4 col-12">
                    <label className="form-label">
                          Assign Leave Type <span style={{ color: "red" }}>*</span>
                        </label>
                    <Select
                            mode="multiple"
                            value={leave_types}
                            onFocus={onFocus}
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            placeholder="Please select"
                            onChange={onChangeLeaves}
                            filterOption={(input, option) =>
                              option.children
                                .toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            required
                          >
                            {leave_type_list.map((item, i) => {
                              return (
                                <Option value={item.id}>
                                  {item.leave_type}
                                </Option>
                              );
                            })}
                          </Select>

                    </div>
                      <div className="col-md-8 col-12">
                      <div className="form-group">
                        <label for="form-action-7">Notes</label>
                        <textarea
                          id="notes"
                          rows="2"
                          className="form-control"
                          name="notes"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                        </div>
                        </div>
                      </div>
                    <hr />
                    <div className="form-row">
                      <h4 className="card-title">
                        System Credentials - Mobile
                      </h4>
                    </div>
                    <div className="form-row">
                      <div className="col-md-4 col-12">
                        <div className="form-group position-relative">
                          <label for="form-action-3">
                            Login Email <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="ofs_email"
                            className="form-control"
                            placeholder="Official Email"
                            name="ofs_email"
                            value={email}
                            onChange={(e) => setOfsEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group position-relative">
                          <label for="form-action-4">
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="password"
                            id="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group position-relative">
                          <label for="form-action-4">
                            Confirmed Password{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="password"
                            id="confirmed_pwd"
                            className="form-control"
                            placeholder="Confirmed Password"
                            name="confirmed_pwd"
                            value={confirmedPwd}
                            onChange={(e) => setConfirmedPwd(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mr-2 mb-3 float-right"
                      disabled={buttonLoading}
                    >
                      {buttonLoading && (
                        <>
                          <i className="ft-refresh-ccw"> </i>
                          <span>Submitting...</span>
                        </>
                      )}
                      {!buttonLoading && (
                        <>
                          <i className="ft-check-square mr-1"></i>
                          <span> Create New Employee</span>{" "}
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default NewEmployee;
