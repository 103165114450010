import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getDashboardData } from "../services/DashboardAPIServices";
import { displayUrl } from "../UrlPaths";
import { PermissionContext } from "../context/PermissionCheck.context";
import { getActiveTeams} from "../services/MasterFileAPIServices";
import { Select } from "antd";

function Dashboard() {
  let root_pathname = window.location.pathname;
  root_pathname = root_pathname.slice(1);
  let root_array = root_pathname.split("/");
  let current_path = root_array[0];
  var current_date = "";
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const date = new Date().getDate();

  if (month < 10) {
    current_date = year + "-0" + month;
  } else {
    current_date = year + "-" + month;
  }
 
  const spinner = document.getElementById("spinner");

  const [PermissionState, setPermissionState] = useState(
    useContext(PermissionContext)
  );
  const [totalLeaves, setTotalLeaves] = useState("0");
  const [totalEmp, setTotalEmp] = useState("0");
  const [totalDesignation, setTotalDesignation] = useState("0");
  const [totalUnreadMsg, setTotalUnreadMsg] = useState("0");
  const [upcomingBirthdayList, setUpcomingBirthdayList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [graphDateList, setGraphDateList] = useState([]);
  const [graphEmpCountList, setGraphEmpCountList] = useState([]);
  const [from_year, setFromYear] = useState("");
  const [from_month, setFromMonth] = useState("");
  const [from_date, setFromDate] = useState("");
  const [teamList, setTeamList] = useState([]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthIndex = new Date().getMonth();
  let monthName = monthNames[monthIndex];

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "spline",
    },
    title: {
      text: "Daily Attendance Variance",
    },
    yAxis: {
      title: {
        text: "Count",
      },
    },
    xAxis: {
      type: "datetime",
      // tickInterval: 1,
      // breaks: [
      //   {
      //     from: 1537567200000,
      //     to: 1537740000000,
      //     breakSize: 1,
      //   },
      // ],
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
      series: {
        pointStart: Date.UTC(from_year, parseInt(from_month) - 1, 1),
        pointInterval: 24 * 3600 * 1000, // one day
      },
    },

    series: [
      {
        name: "Employees",
        data: graphEmpCountList,
      },
    ],
  };



  useEffect(() => {
    getActiveTeams().then((res) => {
      let newRow = {'value':'0','label':'All Teams'};
      res.push(newRow);
      setTeamList(res.reverse());
    });

    loadData(0);
   
  }, []);

  function loadData(team){

    const data = {teamID: team}
    getDashboardData(data).then((response) => {
      setTotalLeaves(response.total_leave_request_count);
      setTotalEmp(response.total_emp_count);
      setTotalDesignation(response.total_designation_count);
      setTotalUnreadMsg(response.unread_messages_count);
      setUpcomingBirthdayList(response.upcoming_birthday_list);
      setLeaveList(response.current_month_leaves);
      setGraphEmpCountList(response.present_count);
      setFromYear(response.from_year);
      setFromMonth(response.from_month);
      setFromDate(response.from_date);

      // const filteredData = response.present_count.filter(item => item <= new Date().getDate());
      const filteredData = response.present_count.splice(new Date().getDate(), response.present_count.length );
 
      spinner.style.display = "none";
    });
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    loadData(value);
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
          <div className="card gradient-blackberry">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">{totalEmp}</h3>
                    <span>Employees</span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-users font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
                <Link to={`/existing-employees`}>
                  <span className="menu-title ml-3">More</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
          <div className="card gradient-ibiza-sunset">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">{totalLeaves}</h3>
                    <span>Monthly Leaves </span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-user-x font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart1"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
                <Link to={`/leave`}>
                  <span className="menu-title ml-3">More</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
          <div className="card gradient-flickr">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">
                      {totalDesignation}
                    </h3>
                    <span>Designations</span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-trending-up font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart2"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
                <Link to={`/designations`} >
                  <span className="menu-title ml-3">More</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
          <div className="card gradient-pomegranate">
            <div className="card-content">
              <div className="card-body py-0">
                <div className="media pb-1">
                  <div className="media-body white text-left">
                    <h3 className="font-large-1 white mb-0">
                      {totalUnreadMsg}
                    </h3>
                    <span>Messages</span>
                  </div>
                  <div className="media-right white text-right">
                    <i className="ft-credit-card font-large-1"></i>
                  </div>
                </div>
              </div>
              <div
                id="Widget-line-chart3"
                className="height-25 WidgetlineChart WidgetlineChartshadow mb-2"
              >
                {" "}
                <Link to={`/messages`}>
                  <span className="menu-title ml-3">More</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row match-height">
        <div className="col-xl-8 col-lg-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title mb-0">
                <span>Attendance Chart - {monthName}</span>
              </h4>
              <div>
              <Select
              defaultValue="0"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={teamList}
            />
              </div>
            </div>
            <div className="card-content">
              <div className="card-body">
                <div className="chart-info mb-3"></div>
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-12">
          <div className="card" style={{ background: "#f2f6fa" }}>
            <div className="card-header">
              <h4 className="card-title mb-0">Upcoming Birthdays </h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                {upcomingBirthdayList.length > 0 ? (
                  upcomingBirthdayList.map((item, i) => {
                    return (
                      <div className="media pt-0 pb-3">
                        <img
                          className="mr-3 avatar"
                          src={`${displayUrl}/${item.profile_img_path}`}
                          alt="Avatar"
                          width="35"
                          height="35"
                        />
                        <div className="media-body">
                          <h4 className="font-medium-1 mb-0">
                            {item.full_name}
                            <span></span>
                          </h4>
                          <span className="badge bg-light-info">
                            {item.designation_name}
                          </span>
                          <div class="d-flex justify-content-end mb-1">
                            {" "}
                            <h6>{item.date}</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    {" "}
                    <span className="badge bg-light-info">
                      No Any Birthdays
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mb-0"></h4>
            </div>
            <div className="card-content">
              <div className="card-body"></div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mb-0">Today's Leaves </h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                {leaveList.length > 0 ? (
                  leaveList.map((item, i) => {
                    return (
                      <div className="media pt-0 pb-4">
                        <img
                          className="mr-3 avatar"
                          src={`${displayUrl}/${item.profile_img_path}`}
                          alt="Avatar"
                          width="35"
                          height="35"
                        />
                        <div className="media-body">
                          <h4 className="font-medium-1 mb-0">
                            {item.name_with_initial}
                            <span></span>
                          </h4>
                          <span className="badge bg-light-danger">
                            {item.leave_type}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <span className="badge bg-light-info">No Any Leaves</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
