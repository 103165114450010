import { createContext } from "react";
import { getGrantedPermissions } from "../services/PermissionAPIServices";
import $ from "jquery";

let access_checking = false;
let checking_count = 0;

const user_role = localStorage.getItem("hms_user_role");
let root_pathname = window.location.pathname;
root_pathname = root_pathname.slice(1);
let root_array = root_pathname.split("/");
const permission_role = {
  user_role,
};
getGrantedPermissions(permission_role).then((response) => {
  $.each(response.data, function (k, v) {
    const href_path = v.href;

    if (href_path == root_array[0]) {
      checking_count++;
    } else if (
      href_path == "existing-employees" &&
      root_array[0] == "employee-edit"
    ) {
      checking_count++;
    } else if (
      href_path == "existing-employees" &&
      root_array[0] == "employee-view"
    ) {
      checking_count++;
    } else if (root_array[0] == "account") {
      checking_count++;
    }
  });

  if (checking_count == 0) {
    access_checking = false;
  } else {
    access_checking = true;
  }
});

export const PermissionContext = createContext(access_checking);
