import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getAllCompaniesList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllCompanies',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getSpecificCompanyData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getSpecificCompany`,data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    return [];
  }
}


export async function createCompanyData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/createCompany`, data,
        { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} });

    return response;
  } catch (error) {
    return error;
  }
}

export async function getCompanyContentTypeList() {
  try {
    const response = await axios.get(
        APIEndpoint+'/getCompanyContentTypes',
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function updateCompanyData(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/updateCompanyDetails`, data,
        { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} });

    return response;
  } catch (error) {
    return error;
  }
}

export async function getCompanyAttendanceType() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getCompanyAttendanceType',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}
