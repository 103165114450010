import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { getRightsAccess } from "./services/PermissionAPIServices";
import { UserContext } from "./context/Auth.context";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "./Routers";

function App() {
  const state = useContext(UserContext);
  let root_pathname = window.location.pathname;
  root_pathname = root_pathname.slice(1);
  let root_array = root_pathname.split("/");
  let current_path = root_array[0];

  const [pageLoading, setPageLoading] = useState(true);
  const [PermissionState, setPermissionState] = useState(false);

  const spinner = document.getElementById("spinner");
  const user_role = localStorage.getItem("hms_user_role");

  const permission_role = {
    user_role,
    current_path,
  };
  useEffect(() => {
    if (user_role == null) {
      spinner.style.display = "none";
    } else {
      getRightsAccess(permission_role).then((res) => {
        setPermissionState(res);
        spinner.style.display = "none";
        setPageLoading(false);

      });
    }
  }, []);
  if (!state) {
    // Redirect
    return (
      <BrowserRouter>
        <UnauthenticatedRoutes />
      </BrowserRouter>
    );
  }
  return (
    <>
      <div
        className="vertical-layout vertical-menu 2-columns  navbar-sticky"
        data-menu="vertical-menu"
        data-col="2-columns"
      >
        {PermissionState ? (
          <BrowserRouter>
            <Navbar />
            <div className="wrappe">
              <Sidebar />

              <div className="main-panel">
                <div className="main-content">
                  <div class="content-overlay"></div>
                  <div className="content-wrapper">
                    <AuthenticatedRoutes />
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </BrowserRouter>
        ) : (
          !pageLoading && !PermissionState ? (<>
            <section id="error" class="auth-height">
              <div class="container-fluid">
                <div class="row full-height-vh">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="row">
                      <div class="col-12 text-center">
                        <img
                          src="../../../app-assets/img/gallery/error.png"
                          alt=""
                          class="img-fluid error-img mt-2"
                          height="300"
                          width="400"
                        />
                        <h1 class="mt-4">401 - Access Denied!</h1>
                        <div class="w-75 error-text mx-auto mt-4">
                          <p>Sorry You don't have permission to this page.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>) : (<></>)

        )}


      </div>
    </>
  );
}

export default App;
